import { createGlobalStyle } from "styled-components";

import FooterComponent from "./components/Footer";
import HomeModalComponent from "./components/HomeModal";
import Avatar from "./components/Avatar";
import ArrowButton from "./components/ArrowButton";
import SidebarNav, { SidebarLink } from "./components/SidebarNav";
import { Toggle } from "./components/Toggle";
import { LiveOnly } from "./components/LiveOnly";

export { LoadingSpinner, PageLoader } from "./components/Loader";
export { TestOnly } from "./components/TestOnly";
export { ConditionalWrapper } from "./components/ConditionalWrapper";
export { SlideOver, type SlideOverHeaderProps } from "./components/SlideOver";
export { ErrorBox } from "./components/ErrorBox";

export const Footer = FooterComponent;
export const HomeModal = HomeModalComponent;

const GlobalStyle = createGlobalStyle`
    html,
    body {
        padding: 0;
        margin: 0;
        @apply font-sans;
        height: 100%;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
    }

    .link {
      @apply cursor-pointer border-b border-brand-accent text-brand-accent hover:text-white hover:bg-green duration-300 ease-in-out;
    }
`;

export default GlobalStyle;
export { Avatar };
export { ArrowButton };
export { SidebarNav };
export { SidebarLink };
export { Toggle };
export { LiveOnly };

export { LoadMoreButton } from "./LoadMoreButton";
