import { sanitizeUrl } from "@braintree/sanitize-url";
import { DASHBOARD_URL } from "@bay1/ui/urlHelper";

function getRefreshAuthUri(href: string) {
  return `${DASHBOARD_URL}/auth/signin?RedirectURL=${encodeURI(
    sanitizeUrl(href),
  )}`;
}

export { getRefreshAuthUri };
