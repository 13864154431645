import { sanitizeUrl } from "@braintree/sanitize-url";

/* eslint-disable fp/no-mutation */
const isSupported = (() => {
  try {
    const testKey = "testKey1";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
})();

export interface ActiveOrganizationStorage {
  setRedirectURL: (value: string) => void;
  getRedirectURL: () => string;
  getIntentToGoLive: () => string | null;
  getIsBannerDismissed: () => string | null;
  setIsBannerDismissed: () => void;
  setIntentToGoLive: () => void;
}

export function localStorageProxy(): ActiveOrganizationStorage {
  const inMemoryStorage: Record<string, string> = {};

  function getRedirectURL(): string {
    if (isSupported) {
      const redirectURL = localStorage.getItem("RedirectURL");
      if (redirectURL !== null) {
        return decodeURIComponent(redirectURL);
      }
    }
    if ("RedirectURL" in inMemoryStorage) {
      const redirectURL = inMemoryStorage.RedirectURL;
      if (redirectURL) {
        return sanitizeUrl(decodeURIComponent(redirectURL));
      }
    }
    return "";
  }

  function getIntentToGoLive(): string | null {
    if (isSupported) {
      return localStorage.getItem("IntentToGoLive");
    }
    if ("IntentToGoLive" in inMemoryStorage) {
      return inMemoryStorage.IntentToGoLive;
    }
    return null;
  }

  function getIsBannerDismissed(): string | null {
    if (isSupported) {
      return localStorage.getItem("isBannerDismissed");
    }
    if ("isBannerDismissed" in inMemoryStorage) {
      return inMemoryStorage.isBannerDismissed;
    }
    return null;
  }

  function setRedirectURL(value: string): void {
    if (isSupported) {
      localStorage.setItem("RedirectURL", value);
    } else {
      inMemoryStorage.RedirectURL = value;
    }
  }

  function setIsBannerDismissed(): void {
    if (isSupported) {
      localStorage.setItem("isBannerDismissed", "true");
    } else {
      inMemoryStorage.isBannerDismissed = "true";
    }
  }

  function setIntentToGoLive(): void {
    if (isSupported) {
      localStorage.setItem("IntentToGoLive", "true");
    } else {
      inMemoryStorage.IntentToGoLive = "true";
    }
  }

  return {
    setRedirectURL,
    getRedirectURL,
    getIsBannerDismissed,
    setIsBannerDismissed,
    getIntentToGoLive,
    setIntentToGoLive,
  };
}
