"use client";

import { Transition } from "@headlessui/react";
import Link from "next/link";
import React from "react";

interface HomeModalProperties {
  headline: string;
  message: string;
  isVisible: boolean;
  buttonText?: string;
  closeCallback?: () => void;
}

const HomeModalComponent = ({
  headline,
  isVisible,
  message,
  buttonText,
  closeCallback,
}: Readonly<HomeModalProperties>): JSX.Element =>
  isVisible ? (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Transition
          enter="east-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isVisible}
        >
          <div aria-hidden="true" className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            aria-hidden="true"
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
          >
            &nbsp;
          </span>
          <div
            aria-labelledby="modal-headline"
            aria-modal="true"
            className="rounded-highnote relative inline-block overflow-hidden bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            role="dialog"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-black">
                {/* Heroicon name: check */}
                <svg
                  aria-hidden="true"
                  className="h-6 w-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13l4 4L19 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="font-display text-lg leading-6"
                  id="modal-headline"
                >
                  {headline}
                </h3>
                <div className="mt-2">
                  <p className="text-sm">{message}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              {closeCallback ? (
                <button
                  className="button button-black inline-flex w-full justify-center"
                  onClick={closeCallback}
                  type="button"
                >
                  {buttonText ?? "Close"}
                </button>
              ) : (
                <Link href="/">
                  <button
                    className="button button-black inline-flex w-full justify-center"
                    type="button"
                  >
                    Back home
                  </button>
                </Link>
              )}
            </div>
          </div>
          {/* </Transition> */}
        </Transition>
      </div>
    </div>
  ) : (
    <></>
  );

export default HomeModalComponent;
