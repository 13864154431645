"use client";

import type { PropsWithChildren } from "react";
import React from "react";

export const FeatureFlag = ({
  children,
  flag,
}: Readonly<PropsWithChildren<{ flag: Readonly<boolean> }>>): JSX.Element => {
  if (flag) {
    return <>{children}</>;
  }

  return <></>;
};
