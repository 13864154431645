"use client";

import React from "react";
import classNames from "classnames";

const ArrowButton = ({
  isDropdown,
  isPrimary,
}: Readonly<{
  isPrimary: boolean;
  isDropdown?: boolean;
}>): JSX.Element => {
  if (isPrimary) {
    return (
      <svg
        className={classNames("button-arrow", {
          "rotate-90": isDropdown,
        })}
        width="33"
        height="20"
        viewBox="0 0 33 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 16L28.9929 10.007C28.9968 10.0031 28.9968 9.99673 28.9929 9.99282L23 4"
          strokeWidth="2"
        />
        <line x1="16" y1="10" x2="29" y2="10" strokeWidth="2" />
        <line
          x1="3"
          y1="10"
          x2="16"
          y2="10"
          strokeWidth="2"
          strokeDasharray="2 4"
        />
      </svg>
    );
  }

  return (
    <svg
      className={classNames("button-arrow-small", {
        "rotate-90": isDropdown,
      })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16L15.9929 10.007C15.9968 10.0031 15.9968 9.99673 15.9929 9.99282L10 4"
        strokeWidth="2"
      />
      <line x1="3" y1="10" x2="16" y2="10" strokeWidth="2" />
    </svg>
  );
};

export default ArrowButton;
