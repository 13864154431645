"use client";

import classNames from "classnames";
import React from "react";

export const LoadingSpinner = ({
  color = "#000",
  secondary = false,
}: Readonly<{
  color?: string;
  secondary?: boolean;
}>): JSX.Element => (
  <svg
    className={classNames("animate-spin stroke-current", { "ml-2": secondary })}
    height={secondary ? 17 : 20}
    viewBox="0 0 38 38"
    width={secondary ? 17 : 20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%">
        <stop offset="0%" stopColor={color} stopOpacity={0} />
        <stop offset="63.146%" stopColor={color} stopOpacity=".631" />
        <stop offset="100%" stopColor={color} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          id="Oval-2"
          stroke="url(#a)"
          strokeWidth={2}
        />
        <circle cx={36} cy={18} fill={color} r={1} />
      </g>
    </g>
  </svg>
);

export const LoadingLogo = ({}: Readonly<{}>): JSX.Element => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-loader"
  >
    <circle cx="25" cy="25" r="25" fill="#55F5A3" />
    <path
      d="M27.2294 22.7692C28.4582 23.998 28.4582 25.9904 27.2294 27.2193C26.0005 28.4481 24.0082 28.4481 22.7793 27.2193C21.5504 25.9904 21.5504 23.998 22.7793 22.7692C24.0082 21.5403 26.0005 21.5403 27.2294 22.7692Z"
      fill="black"
    />
    <path
      d="M37.0877 28.1588C37.9262 28.185 38.7529 27.8624 39.3929 27.2224C40.6206 25.9947 40.6206 24.0017 39.3916 22.7727C38.1639 21.545 36.1708 21.545 34.9431 22.7727C34.3031 23.4126 33.9492 24.2864 34.0054 25.1131C34.0472 25.8785 33.8774 26.6464 33.3027 27.2211C32.728 27.7958 31.9392 27.8976 31.1438 27.9225C30.3871 27.8934 29.6707 28.153 29.0711 28.6616C29.0011 28.7204 28.9331 28.7826 28.8673 28.8484C28.8649 28.8508 28.8625 28.8533 28.8601 28.8557C28.8582 28.8576 28.8562 28.8596 28.8543 28.8615C28.688 29.0278 28.5442 29.208 28.423 29.3985C28.0696 29.9457 27.8892 30.5793 27.9296 31.1889C27.9714 31.9543 27.8016 32.7222 27.2269 33.2969C26.6522 33.8716 25.8634 33.9734 25.068 33.9982C24.2177 33.9656 23.4184 34.2973 22.7785 34.9373C21.5508 36.165 21.5508 38.158 22.7811 39.3857C24.0088 40.6134 26.0005 40.6147 27.2295 39.3857C27.8695 38.7458 28.1999 37.8798 28.162 37.0453C28.1555 36.3282 28.294 35.5107 28.8686 34.936C29.4433 34.3613 30.1616 34.2177 30.9779 34.2346H31.0119C31.6695 34.2551 32.3198 34.0611 32.8785 33.6678C33.0285 33.5631 33.1714 33.4438 33.3053 33.3099C33.3075 33.3077 33.3098 33.3055 33.312 33.3033C33.3137 33.3016 33.3154 33.2999 33.3171 33.2982C33.6392 32.9761 33.8768 32.6016 34.0299 32.2021C34.1849 31.8044 34.2566 31.3831 34.2378 30.9695C34.2313 30.2525 34.3698 29.4349 34.9444 28.8602C35.5191 28.2855 36.2374 28.1419 37.0537 28.1588H37.0877Z"
      fill="black"
    />
    <path
      d="M24.9203 15.9915C25.7588 16.0176 26.5856 15.6951 27.2255 15.0551C28.4532 13.8274 28.4532 11.8343 27.2242 10.6053C25.9965 9.37764 24.0035 9.37764 22.7758 10.6053C22.1358 11.2453 21.7819 12.1191 21.838 12.9458C21.8798 13.7111 21.71 14.4791 21.1354 15.0538C20.5607 15.6284 19.7718 15.7303 18.9764 15.7551C18.2197 15.7261 17.5034 15.9856 16.9038 16.4943C16.8338 16.553 16.7658 16.6153 16.7 16.6811C16.6976 16.6835 16.6952 16.6859 16.6928 16.6884C16.6908 16.6903 16.6889 16.6922 16.6869 16.6942C16.5206 16.8605 16.3769 17.0407 16.2556 17.2312C15.9023 17.7784 15.7218 18.412 15.7622 19.0216C15.804 19.7869 15.6342 20.5549 15.0596 21.1296C14.4849 21.7042 13.696 21.8061 12.9007 21.8309C12.0504 21.7983 11.2511 22.13 10.6111 22.77C9.38343 23.9977 9.38343 25.9907 10.6137 27.2184C11.8414 28.4461 13.8332 28.4474 15.0622 27.2184C15.7021 26.5784 16.0326 25.7125 15.9947 24.878C15.9882 24.1609 16.1266 23.3433 16.7013 22.7687C17.276 22.194 17.9943 22.0503 18.8106 22.0673H18.8445C19.5021 22.0878 20.1525 21.8938 20.7112 21.5005C20.8612 21.3958 21.0041 21.2765 21.138 21.1426C21.1402 21.1404 21.1424 21.1382 21.1446 21.1359C21.1463 21.1343 21.148 21.1326 21.1497 21.1309C21.4718 20.8088 21.7094 20.4343 21.8626 20.0348C22.0176 19.6371 22.0893 19.2158 22.0705 18.8022C22.064 18.0851 22.2024 17.2675 22.7771 16.6929C23.3517 16.1182 24.0701 15.9745 24.8864 15.9915H24.9203Z"
      fill="black"
    />
  </svg>
);

export const PageLoader = (): JSX.Element => (
  <div className="bg-bone grid h-screen w-screen">
    <div className="mx-auto self-center">
      <LoadingLogo />
    </div>
  </div>
);
