export { CommonAppContext, CommonAppContextProvider } from "./CommonAppContext";
export type { CommonAppState } from "./CommonAppContext";
export { GraphiqlContext, GraphiqlContextProvider } from "./ContextGraphiql";
export type { GraphiqlState } from "./ContextGraphiql";
export { phoneCountryCodes } from "./phoneCountryCodes";
export { USStatesAndTerritories } from "./USStatesAndTerritories";
export { FeatureFlag } from "./FeatureFlag";

export type ArrayElement<Array_> = Array_ extends ReadonlyArray<infer Type>
  ? Type
  : never;
