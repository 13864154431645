"use client";

import type { PropsWithChildren } from "react";

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: Readonly<
  PropsWithChildren<{
    condition: Readonly<boolean>;
    wrapper: React.FC<PropsWithChildren>;
  }>
>): JSX.Element => {
  const Wrapper = wrapper;
  return condition ? <Wrapper>{children}</Wrapper> : <>{children}</>;
};
