"use client";

import React from "react";
import type { FormiumClient } from "@formium/client";
import Link from "next/link";

import { MARKETING_URL } from "../urlHelper";

import NewsletterSignupForm from "./NewsletterSignupForm";

interface FooterProperties {
  formiumClient?: FormiumClient;
  onNewsletterFormSuccess?: () => Promise<void>;
}

const FOOTER_LINK_CLASS_NAME = "group text-sm text-white flex items-center";

const PLATFORM_FOOTER_COLUMN_LINKS = [
  ["platform", "Platform"],
  ["products", "Products"],
  ["pricing", "Pricing"],
  ["blog", "Blog"],
];

const COMPANY_COLUMN_FOOTER_LINKS = [
  ["about", "About"],
  ["press", "Press"],
  ["careers", "Careers"],
  ["contact", "Contact"],
];

const DEVELOPER_COLUMN_FOOTER_LINKS = [
  ["docs", "Documentation"],
  ["docs/reference/query", "API Reference"],
  ["changelog", "Changelog"],
  ["https://status.highnote.com", "Status"],
];

const SOCIAL_COLUMN_FOOTER_LINKS = [
  ["https://www.linkedin.com/company/highnoteplatform", "LinkedIn"],
  ["https://twitter.com/highnotesocial", "Twitter"],
];

const FooterComponent = ({
  formiumClient,
  onNewsletterFormSuccess,
}: Readonly<FooterProperties>): JSX.Element => (
  <footer
    aria-labelledby="footerHeading"
    className="bg-black px-4 py-10 lg:px-10"
  >
    <h2 className="sr-only" id="footerHeading">
      Footer
    </h2>
    <div className="mx-auto max-w-7xl">
      <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-6">
        <div className="col-span-1 mt-5">
          <p className="text-xxs mb-3.5 font-medium uppercase tracking-widest text-gray-400">
            Platform
          </p>
          <ul className="space-y-3">
            {PLATFORM_FOOTER_COLUMN_LINKS.map(([path, linkContent]) => (
              <li key={path}>
                <Link
                  href={`${MARKETING_URL}/${path}`}
                  className={FOOTER_LINK_CLASS_NAME}
                  data-testid={`footer::link::${path}`}
                >
                  {linkContent}
                  <span className="inline-block">
                    <img
                      alt=""
                      className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover:translate-x-1 group-hover:opacity-100"
                      src="/img/white-arrow-icon.svg"
                    />
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-1 mt-5">
          <p className="text-xxs mb-3.5 font-medium uppercase tracking-widest text-gray-400">
            Company
          </p>
          <ul className="space-y-3">
            {COMPANY_COLUMN_FOOTER_LINKS.map(([path, linkContent]) => (
              <li key={path}>
                <Link
                  href={`${MARKETING_URL}/${path}`}
                  className={FOOTER_LINK_CLASS_NAME}
                  data-testid={`footer::link::${path}`}
                >
                  {linkContent}
                  <span className="inline-block">
                    <img
                      alt=""
                      className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover:translate-x-1 group-hover:opacity-100"
                      src="/img/white-arrow-icon.svg"
                    />
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-1 mt-5">
          <p className="text-xxs mb-3.5 font-medium uppercase tracking-widest text-gray-400">
            Developers
          </p>
          <ul className="space-y-3">
            {DEVELOPER_COLUMN_FOOTER_LINKS.map(([path, linkContent]) => (
              <li key={path}>
                <Link
                  href={
                    linkContent === "Status" ? path : `${MARKETING_URL}/${path}`
                  }
                  passHref
                  className={FOOTER_LINK_CLASS_NAME}
                  data-testid={`footer::link::${path}`}
                >
                  {linkContent}
                  <span className="inline-block">
                    <img
                      alt=""
                      className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover:translate-x-1 group-hover:opacity-100"
                      src="/img/white-arrow-icon.svg"
                    />
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-1 mt-5">
          <p className="text-xxs mb-3.5 font-medium uppercase tracking-widest text-gray-400">
            Connect
          </p>
          <ul className="space-y-3">
            {SOCIAL_COLUMN_FOOTER_LINKS.map(([path, linkContent]) => (
              <li key={path}>
                <Link
                  href={path}
                  className={FOOTER_LINK_CLASS_NAME}
                  data-testid={`footer::link::${path}`}
                >
                  {linkContent}
                  <span className="inline-block">
                    <img
                      alt=""
                      className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover:translate-x-1 group-hover:opacity-100"
                      src="/img/white-arrow-icon.svg"
                    />
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="sm:col-span-2">
          {formiumClient && onNewsletterFormSuccess ? (
            <NewsletterSignupForm
              formiumClient={formiumClient}
              onSuccess={onNewsletterFormSuccess}
            />
          ) : undefined}
        </div>
      </div>
      <div className="mt-20 flex items-baseline justify-between border-t border-white pt-8">
        <div>
          <p className="text-xs leading-5 text-gray-400">
            <span className="block text-white">
              ©2023 Highnote Platform, Inc.
            </span>
            Highnote Platform, Inc. is a financial technology company, not a
            bank.
            <br />
            Banking services provided by partner banks, members FDIC.
          </p>
        </div>
        <div className="flex space-x-5">
          <a
            className="border-b border-transparent text-xs text-white hover:border-white"
            href={`${MARKETING_URL}/agreements/terms`}
          >
            Terms
          </a>
          <a
            className="border-b border-transparent text-xs text-white hover:border-white"
            href={`${MARKETING_URL}/agreements/privacy`}
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default FooterComponent;
