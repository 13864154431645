"use client";

import { CommonAppContext } from "@bay1/data/CommonAppContext";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";
import React, { useContext } from "react";

export const OpsOnly = ({
  roles,
  children,
}: Readonly<{
  roles: string[] | string;
  children: React.ReactNode;
}>): JSX.Element => {
  const { activeOrganization: organization } = useContext(CommonAppContext);
  const roleMatches = Array.isArray(roles)
    ? roles?.some((role) => organization?.agentRoles?.includes(role))
    : organization?.agentRoles?.includes(roles ?? "");
  if (
    organization?.profile.environment === IntegrationEnvironment.LIVE &&
    organization?.isAdminUserAccessOrg &&
    roleMatches
  ) {
    return <>{children}</>;
  }

  return <></>;
};
