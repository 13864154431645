"use client";

import React from "react";
import classNames from "classnames";

import type { Maybe } from "@bay1/sdk/generated/graphql";

import { LoadingSpinner } from ".";

export const LoadMoreButton = ({
  hasNextPage,
  isLoading,
  onClick,
}: Readonly<{
  hasNextPage: Maybe<boolean>;
  isLoading: boolean;
  onClick: () => void;
}>): JSX.Element => (
  <button
    className={classNames(
      "border-gray focus:ring-green inline-flex items-center rounded-full border bg-white px-4 py-1 text-xs font-medium leading-5 focus:outline-none focus:ring-2 focus:ring-offset-2",
      {
        "hover:bg-gray-50": !isLoading && hasNextPage,
        "cursor-default": isLoading || hasNextPage === false,
      },
    )}
    data-testid="button::loadMore"
    disabled={hasNextPage === false || isLoading}
    onClick={onClick}
    type="button"
  >
    {/* Heroicon name: solid/plus-sm */}
    <svg
      aria-hidden="true"
      className="-ml-1.5 mr-1 h-4 w-4 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
        fillRule="evenodd"
      />
    </svg>
    {isLoading ? (
      <LoadingSpinner color="#9ca3af" />
    ) : hasNextPage ?? false ? (
      "Load More"
    ) : (
      "No more items"
    )}
  </button>
);
